import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { borders } from "styled-system";
import { graphql } from "gatsby";
import urlJoin from "url-join";

import Card from "../components/Card";
import SEO from "../components/SEO";
import { Heading } from "../components/Primitives";

const Link = styled.a`
  border-bottom: 1px solid var(--link);
  ${borders}
`;

const About = ({
  data: {
    site: {
      siteMetadata: { title, siteUrl, card },
    },
  },
}) => (
  <Fragment>
    <SEO
      title={`About | ${title}`}
      description="Some information about the author of the blog."
      image={urlJoin(siteUrl, card)}
      url={urlJoin(siteUrl, "/about/")}
    />
    <Card id="hello">
      <Heading as="h1" fontSize="2.35em" my="var(--rhythm)">
        About me
      </Heading>
      <p>
        My name is Markos Konstantopoulos and I live in Greece. I have a
        BSC in Computer Science and I work as a freelance web developer.
        My hobbies are programming, gaming, and the NBA.
      </p>
      <Heading as="h2" fontSize="1.8em" my="var(--rhythm)">
        Links
      </Heading>
      <ul>
        <li>
          <Link
            href="https://github.com/MarkosKon"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub account
          </Link>
        </li>
        <li>
          <Link
            href="https://twitter.com/markos_kon"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </Link>
        </li>
      </ul>
      <p />
      <Heading as="h2" fontSize="1.8em" my="var(--rhythm)">
        Side-projects
      </Heading>
      <p>
        JavaScript side-projects and tools I made over the last 2 years.
      </p>
      <Heading as="h3" fontSize="1.35em" my="var(--rhythm)">
        <Link
          borderBottom="none"
          href="https://font-match.netlify.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Font Match
        </Link>
      </Heading>
      <p>
        A Gatsby app that helps me find typeface combinations from Google
        Fonts. You can see how similar are the letters by placing them on
        top of each other or side-by-side. The most useful feature for me
        is the ability to place the fonts in the same line to see if they
        have a similar typographic color. It’s a simple thing to do, but
        I couldn’t find an app that does this.
      </p>
      <Heading as="h3" fontSize="1.35em" my="var(--rhythm)">
        <Link
          borderBottom="none"
          href="https://hsdeckideas.netlify.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hearthstone deck ideas
        </Link>
      </Heading>
      <p>
        A random deck generator built with React, custom Webpack and
        pre-rendering. I’m pretty proud of the algorithm that creates the
        decks—although, it’s kind of a mess from a clean code/performance
        perspective. If I get into machine learning, I want to give it
        another shot in the future.
      </p>
      <Heading as="h3" fontSize="1.35em" my="var(--rhythm)">
        <Link
          borderBottom="none"
          href="https://hsfilter.netlify.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          HSFilter
        </Link>
      </Heading>
      <p>
        A filtering app and deck builder for Hearthstone built with
        Gatsby. The idea was to filter the Hearthstone cards in any way
        possible and discover cards you haven’t thought of adding in the
        deck. The problem was that the filter interface was not
        intuitive.
      </p>
      <Heading as="h3" fontSize="1.35em" my="var(--rhythm)">
        <Link
          borderBottom="none"
          href="https://character-table.netlify.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Character table
        </Link>
      </Heading>
      <p>
        A Gatsby app that presents the characters used by a language in a
        table. Useful if you want to create font subsets and you are not
        sure what characters are essential for the languages you want to
        support. You can search by language or by character. The data are
        from the Unicode <abbr>CLDR</abbr> project.
      </p>
      <Heading as="h3" fontSize="1.35em" my="var(--rhythm)">
        <Link
          borderBottom="none"
          href="https://youtube-comments.netlify.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouΤube comments
        </Link>
      </Heading>
      <p>
        A <abbr>CRA</abbr> app that downloads the comments for a video on
        a single page. You can then search for something inside the
        comments. I use it to figure out if I’m the only weirdo that
        thought of something while watching a video.
      </p>
      <Heading as="h3" fontSize="1.35em" my="var(--rhythm)">
        <Link
          borderBottom="none"
          href="https://oneiro-sch.gr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Oneiro school
        </Link>
      </Heading>
      <p>
        A Gatsby (v1) app I made for a school that showcases page
        transitions with <abbr>SVG</abbr>.
      </p>
    </Card>
  </Fragment>
);

About.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        card
        title
      }
    }
  }
`;

export default About;
